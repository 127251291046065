import axios from "axios";

const umlautMap = {
  '\u00dc': '&#xD4;',
  '\u00c4': '&#xC4;',
  '\u00d6': '&#xD6;',
  '\u00fc': '&#xFC;',
  '\u00e4': '&#xE4;',
  '\u00f6': '&#xF6;',
  '\u00df': '&#xDF;',
}

function replaceUmlaute(str) {
  return str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp('['+Object.keys(umlautMap).join('|')+']',"g"),
      (a) => umlautMap[a]
    );
}

//const test = [searchQuery];
//test.forEach((str) => console.log(str + " -> " + replaceUmlaute(str)))
// searchQuery = replaceUmlaute(searchQuery);


export const getSearchData = (
  comp_id = "147355",
  shop_id,
  register_id,
  operation_id,
  workorder_id,
  searchQuery,
  storeData,
  setLoading
) => {
  var config = {
    method: "post",
    //url: "https://retailrc.datastems.com/api/room-searchX",
    url: "https://retailrapi.beesql.com/api/room-searchX",
    headers: {
      "X-Comapny-id": comp_id,
      "X-Search": replaceUmlaute(searchQuery),
      "X-Shop-id": shop_id,
      "X-Register-id": register_id,
      "X-Operation-Name": operation_id,
      "X-WorkOrder-Id": workorder_id,
      "Content-Type": "text/plain"
    },
    "data": document.getElementById('json-data').innerText,
  };


  function toUnicode(str) {
    return str.split('').map(function (value, index, array) {
      var temp = value.charCodeAt(0).toString(16).toUpperCase();
      if (temp.length > 2) {
        return '\\u' + temp;
      }
      return value;
    }).join('');
  }
  
  
  setLoading(true);
  axios(config)
    .then((resp) => {
      setLoading(false);
      const res = resp;
      //console.log(res.data, "get searched data");
      storeData(
        res.data.map((item) =>
          item.account_id === undefined ? { ...item, account_id: "" } : item
        )
      );
    })
    .catch((err) => {
      setLoading(false);
      console.log(err);
    });
};

export const getRoomCharges = (
  comp_id,
  sale_id,
  room_id,
  account_id,
  guest_name,
  reservation_id,
  refund_id,
  operation_id,
  extra1,
  extra2,
  extra3,
  extra4,
  extra5,
  extra6,
  extra7,
  setPage,
  setErrorMsg
) => {
  
  console.log("get charges input", {
    comp_id,
    sale_id,
    room_id,
    account_id,
    guest_name,
    reservation_id,
    refund_id,
    operation_id,
    extra1,
    extra2,
    extra3,
    extra4,
    extra5,
    extra6,
    setPage,
    setErrorMsg,
  });

  var config = {
    method: "post",
    url: "https://retailrc.datastems.com/api/room-chargesX",
    headers: {
      "X-Comapny-Id": comp_id,
      "X-Sale-Id": sale_id,
      "X-Room-Id": room_id,
      "X-Acount-ID": account_id.replace(/(\r\n|\n|\r)/gm, ""),
      "X-Guest-Name": replaceUmlaute(guest_name.replace(/(\r\n|\n|\r)/gm, "")),
      "X-Reservation-Id": reservation_id,
      "X-Refund-Id": refund_id,
      "X-Operation-Name": operation_id,
      "x-extra1" : extra1,
      "x-extra2" : extra2,
      "x-extra3" : extra3,
      "x-extra4" : extra4,
      "x-extra5" : extra5,
      "x-extra6" : extra6,
      "Content-Type": "text/plain"
    },
    "data": document.getElementById('json-data').innerText,
  };

  //console.log(guest_name.replace(/(\r\n|\n|\r)/gm, ""));


  axios(config)
    .then((resp) => {
      console.log(resp);
      setErrorMsg(resp.data.message2);
      setPage(2);
    })
    .catch((err) => {
      if (err.response) {
        // console.log(err.response.data);
        setErrorMsg(err.response.data.errorDescription);
      }
      setPage(3);
    });
};

export const getPopulate = (
  setSearch,
  companyId,
  customerId,
  operationName,
) => {
  var config = {
    method: "post",
    //url: "https://retailrc.datastems.com/api/custom",
    url: "https://retailrapi.beesql.com/api/custom",
    headers: {
      "X-Comapny-id": companyId,
      "X-Customer-id": customerId,
      "X-Operation": operationName,
      "X-Option1": "2",
      "X-Option2": "3",
    },
  };

  axios(config)
    .then(function (response) {
      setSearch(response.data.value);
    })
    .catch(function (error) {
      console.log(error);
    });
};
